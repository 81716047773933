import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IClientData } from '../../models/ClientData';
import { LoanService } from '../../services/loan.service';
import { RootState } from '../types';

type LoanData = {
    loanBlankId: string;
    isBlankLoading: boolean;
};

const initState: LoanData = {
    loanBlankId: '',
    isBlankLoading: false,
};

export const createLoan = createAsyncThunk<
    string,
    { token: string; loanBlankId?: string; clientData?: IClientData }
>('/loan/create', async ({ loanBlankId, token, clientData }, { rejectWithValue, getState }) => {
    try {
        const state = getState() as RootState;
        const data = await LoanService.createLoan({
            loanBlankId: loanBlankId || state.loanStore.loanBlankId,
            token: token,
            configuration: state.calculatorStore.params,
            personalData: clientData || state.clientStore.clientData,
            cart: state.applicationStore.order.cart,
            bankProducts: {
                bankProductIds: Object.values(state.calculatorStore.selectedBankProducts),
                excludedBanks: [],
            },
            retailPointId: state.applicationStore.order.retailPointId,
        });
        return data.loanBlankId;
    } catch (error) {
        return rejectWithValue(error);
    }
});

const loanSlice = createSlice({
    name: 'loan',
    initialState: initState,
    reducers: {
        setLoanBlankId(state, action: PayloadAction<string>) {
            state.loanBlankId = action.payload;
        },
        clearLoanStore() {
            return initState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createLoan.pending, (state) => {
            state.isBlankLoading = true;
        });
        builder.addCase(createLoan.fulfilled, (state, action) => {
            state.isBlankLoading = false;
            state.loanBlankId = action.payload;
        });
        builder.addCase(createLoan.rejected, (state) => {
            state.isBlankLoading = false;
        });
    },
});

export const { setLoanBlankId, clearLoanStore } = loanSlice.actions;
export default loanSlice.reducer;
