import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Bank } from '../../models/Bank';
import { ScoringResponse } from '../../models/loan/ScoringResponse';
import { ScoringBank } from '../../models/ScoringBank';
import { LoanService } from '../../services/loan.service';
import { ResponseErrorType } from '../../shared/types/API/ResponseSuccessType';
import { catchApiError } from '../../utils/catchError';

type ScoringData = {
    decisions: ScoringBank[];
    clickedButtons: {
        [offerId: string]: string;
    };
    isLoading: boolean;
    selectedDecision: Bank | null;
};

const initState: ScoringData = {
    decisions: [],
    clickedButtons: {},
    isLoading: false,
    selectedDecision: null,
};

export const scoringResult = createAsyncThunk<
    ScoringResponse,
    { loanBlankId: string; abortController?: AbortController }
>(
    'scoring/scoringResult',
    async ({ loanBlankId, abortController }, { rejectWithValue, dispatch }) => {
        try {
            return await LoanService.scoringResult(loanBlankId, abortController);
        } catch (error) {
            catchApiError(error as ResponseErrorType, dispatch);
            return rejectWithValue(error);
        }
    }
);

const scoringSlice = createSlice({
    name: 'scoring',
    initialState: initState,
    reducers: {
        setDecisions(state, action: PayloadAction<ScoringBank[]>) {
            state.decisions = action.payload;
        },
        setSelectedDecision(state, action: PayloadAction<Bank>) {
            state.selectedDecision = action.payload;
        },
        setClickedButton(state, action: PayloadAction<{ offerId: string; buttonId: string }>) {
            const { offerId, buttonId } = action.payload;
            state.clickedButtons[offerId] = buttonId;
        },
        removeClickedButton(state, action: PayloadAction<{ offerId: string }>) {
            delete state.clickedButtons[action.payload.offerId];
        },
        setIsDecisionsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        clearScoringStore() {
            return initState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(scoringResult.fulfilled, (state, action) => {
            const { items } = action.payload;
            console.log('action.payload', action.payload);
            state.isLoading = false;
            state.decisions = items;
        });
    },
});

export const {
    setDecisions,
    setIsDecisionsLoading,
    setSelectedDecision,
    clearScoringStore,
    setClickedButton,
    removeClickedButton,
} = scoringSlice.actions;

export default scoringSlice.reducer;
