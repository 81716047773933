import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICalculationInitConfig } from '../models';
import { ICalculatorParams } from '../models/calculator/CaclulatorParams';
import { CalculatorBank } from '../models/CalculatorBank';
import { CalculatorService } from '../services/calculator.service';
import { ResponseErrorType } from '../shared/types/API/ResponseSuccessType';
import { catchApiError } from '../utils/catchError';
import { Nullable } from '../utils/types/Nullable';

const defaultParams: ICalculatorParams = {
    fee: 0,
    sum: 0,
    type: 'INSTALLMENT',
    services: [],
    term: 0,
};

type InitState = {
    params: ICalculatorParams;
    bankProductCalculations: CalculatorBank[];
    paramsConfig: Nullable<ICalculationInitConfig>;
    isCalculating: boolean;
    isParamsLoading: boolean;
    selectedBankProducts: string[];
};

const initState: InitState = {
    params: defaultParams,
    bankProductCalculations: [],
    paramsConfig: null,
    isCalculating: true,
    isParamsLoading: true,
    selectedBankProducts: [],
};

export const calculate = createAsyncThunk<CalculatorBank[], ICalculatorParams>(
    'calculator/calculate',
    async (params, { rejectWithValue, dispatch }) => {
        try {
            dispatch(setParams(params));
            const data = await CalculatorService.calculate(params);
            return data.items;
        } catch (error) {
            catchApiError(error as ResponseErrorType, dispatch);
            return rejectWithValue(error);
        }
    }
);

export const fetchCalculatorConfig = createAsyncThunk<ICalculationInitConfig>(
    'calculator/initConfig',
    async (_, { rejectWithValue, dispatch }) => {
        try {
            return await CalculatorService.init();
        } catch (error) {
            catchApiError(error as ResponseErrorType, dispatch);
            return rejectWithValue(error);
        }
    }
);
const calculatorSlice = createSlice({
    name: 'calculator',
    initialState: initState,
    reducers: {
        clearCalculatorStore() {
            return initState;
        },
        setParams(state, action: PayloadAction<ICalculatorParams>) {
            state.params = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchCalculatorConfig.pending, (state) => {
            state.paramsConfig = null;
            state.isParamsLoading = true;
        });
        builder.addCase(fetchCalculatorConfig.rejected, (state) => {
            state.paramsConfig = null;
            state.isParamsLoading = false;
        });
        builder.addCase(
            fetchCalculatorConfig.fulfilled,
            (state, action: PayloadAction<ICalculationInitConfig>) => {
                state.paramsConfig = action.payload;
                state.isParamsLoading = false;
            }
        );
        builder.addCase(calculate.pending, (state) => {
            state.isCalculating = true;
            state.bankProductCalculations = [];
        });
        builder.addCase(calculate.fulfilled, (state, action) => {
            state.isCalculating = false;
            state.bankProductCalculations = action.payload;
            const selectedBanksProduct: string[] = [];
            action.payload.forEach((item) => {
                selectedBanksProduct.push(item.bankProducts[0].id);
            });
            state.selectedBankProducts = selectedBanksProduct;
        });
        builder.addCase(calculate.rejected, (state) => {
            state.isCalculating = false;
            state.bankProductCalculations = [];
        });
    },
});

export const { setParams, clearCalculatorStore } = calculatorSlice.actions;
export default calculatorSlice.reducer;
