import { IValidation } from '../shared/types/IValidation';

export const isMinLengthValidation = (length: number) => {
    return (value: string): IValidation => {
        if (value && value.length < length) {
            return {
                errorMessage: `Минимальное количество символов в поле ${length}, сейчас ${value.length}`,
            };
        }
        return { errorMessage: '' };
    };
};
