import React, { ChangeEvent, memo, useState } from 'react';
import { InputType } from './types';
import BaseInput from './BaseInput';
import classes from './input.module.css';
import classNames from 'classnames';

const Input: InputType = ({
    prefix,
    postFix,
    label,
    containerClassNames,
    wrapperClassNames,
    status = 'correct',
    extraInfo,
    containerErrorClassNames,
    wrapperErrorClassNames,
    prefixClassNames,
    labelTextErrorClassNames,
    labelTextClassNames,
    extraInfoClassNames,
    extraErrorInfoClassNames,
    onBlur,
    onFocus,
    disabled,
    onChange,
    ...props
}) => {
    const [isActive, setIsActive] = useState(false);
    const [touched, setTouched] = useState(false);
    const inputWrapperClasses = classNames(
        classes.input__wrapper,
        { [classes.input__wrapper_error]: status === 'error' && touched },
        wrapperClassNames,
        wrapperErrorClassNames
    );
    const containerClasses = classNames(
        classes.input,
        {
            [classes.input__disabled]: disabled,
        },
        containerClassNames,
        containerErrorClassNames
    );
    const prefixClasses = classNames(classes.input__prefix, prefixClassNames);
    const labelTextClasses = classNames(
        classes.input__label_text,
        {
            [classes.input__label_text_error]: status === 'error' && touched,
        },
        labelTextClassNames,
        labelTextErrorClassNames
    );
    const extraInfoClasses = classNames(
        classes.input_extraInfo,
        {
            [classes.input_extraInfo_error]: status === 'error' && touched,
        },
        extraInfoClassNames,
        extraErrorInfoClassNames
    );
    const onBlurHandler = (event: React.FocusEvent<HTMLInputElement>) => {
        setIsActive(false);
        setTouched(true);
        if (onBlur) onBlur(event);
    };
    const onFocusHandler = (event: React.FocusEvent<HTMLInputElement>) => {
        setIsActive(true);
        if (onFocus) onFocus(event);
    };
    const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setTouched(false);
        onChange(event);
    };
    return (
        <div className={containerClasses}>
            <div className={inputWrapperClasses}>
                {prefix && !disabled ? <span className={prefixClasses}>{prefix}</span> : null}
                <div className={classes.input__control}>
                    <div className={classes.input__label}>
                        <span className={labelTextClasses}>{label}</span>
                    </div>
                    <BaseInput
                        {...props}
                        onChange={onChangeHandler}
                        onBlur={onBlurHandler}
                        onFocus={onFocusHandler}
                        isActive={isActive}
                        disabled={disabled}
                    />
                </div>
                {postFix && !disabled ? (
                    <span className={classes.input__postfix}>{postFix}</span>
                ) : null}
            </div>
            {extraInfo ? (
                status === 'error' && touched ? (
                    <span className={extraInfoClasses}>{extraInfo}</span>
                ) : status !== 'error' ? (
                    <span className={extraInfoClasses}>{extraInfo}</span>
                ) : null
            ) : null}
        </div>
    );
};
export default memo(Input);
