import { configureStore } from '@reduxjs/toolkit';
import stepperReducer from './StepperSlice';
import appReducer from './app/AppSlice';
import calculatorReducer from './CalculatorSlice';
import clientReducer from './client/ClientSlice';
import loanReducer from './loan/LoanSlice';
import scoringReducer from './scoring/scoring';
import toastReducer from './toast/ToastSlice';
export const Store = configureStore({
    reducer: {
        stepperStore: stepperReducer,
        applicationStore: appReducer,
        calculatorStore: calculatorReducer,
        clientStore: clientReducer,
        loanStore: loanReducer,
        scoringStore: scoringReducer,
        toastStore: toastReducer,
    },
});

export default Store;
