import React, { useMemo } from 'react';
import { useAppSelector } from '../../hooks/useAppSelector';
import { StepsData } from '../../shared/constants/Steps';

const Content = () => {
	const { activeStep } = useAppSelector((state) => state.stepperStore);
	const StepContent = useMemo(() => {
		return StepsData[activeStep - 1].Component;
	}, [activeStep]);
	return <StepContent />;
};
export default Content;
