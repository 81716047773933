import React from 'react';
import classes from './button.module.css';
import { ButtonProps } from './types';
import classNames from 'classnames';

const Button = ({
	disabled,
	onClickHandler,
	type = 'default',
	children,
	className,
	block,
	danger,
	withExtraBackGrounds,
}: ButtonProps) => {
	const buttonClassNames = classNames(
		classes.button,
		{
			[classes.button_block]: block,
			[classes.button__default]: type === 'default',
			[classes.button_primary]: type === 'primary',
			[classes.button_danger]: danger,
		},
		className
	);
	return (
		<button
			disabled={disabled}
			onClick={onClickHandler}
			className={buttonClassNames}>
			{children}
			{withExtraBackGrounds ? (
				<>
					<div
						className={`${withExtraBackGrounds.firstBackGround}`}
					/>
					<div
						className={`${withExtraBackGrounds.secondBackGround}`}
					/>
				</>
			) : null}
		</button>
	);
};
export default Button;
