import {
    isValidationResponseError,
    ResponseErrorType,
} from '../shared/types/API/ResponseSuccessType';
import { Dispatch } from '@reduxjs/toolkit';
import { addToast } from '../store/toast/ToastSlice';
import uuid from 'react-uuid';

export const catchApiError = (error: ResponseErrorType, dispatch: Dispatch) => {
    if (!error) return null;
    if (isValidationResponseError(error)) {
        dispatch(
            addToast({
                id: uuid(),
                detail: error.detail,
                title: error.title,
                violations: error.violations,
            })
        );
    } else {
        dispatch(
            addToast({
                id: uuid(),
                detail: error.detail,
                title: error.title,
                status: error.status,
            })
        );
    }
};
