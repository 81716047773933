import { FirstStep, SecondStep, ThirdStep, FinalStep } from '../../steps';

export const StepsData = [
    {
        id: 'first',
        title: 'Выберите первоначальный взнос и срок кредита',
        Component: FirstStep,
    },
    {
        id: 'second',
        title: 'Введите персональные данные',
        Component: SecondStep,
    },
    {
        id: 'third',
        title: 'Выберите банк для оформления кредита',
        Component: ThirdStep,
    },
    {
        id: 'fourth',
        title: 'Какой-то заголовок',
        Component: FinalStep,
    },
];
