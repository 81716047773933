export const moneyFormat = <T>(
	price: T,
	withPenny = false,
	currency = 'KZT',
	locales = 'ru-KZ'
): string => {
	if (price === undefined) return '';
	const numericPrice = Number(price);
	const formatter = new Intl.NumberFormat(locales, {
		style: 'currency',
		currency: currency,
	});
	let formattedPrice = formatter.format(numericPrice);
	if (!withPenny) {
		formattedPrice = formatter.format(Math.round(numericPrice));
		formattedPrice = formattedPrice.replace(/,\d{2}/, '');
	}
	return formattedPrice;
};
