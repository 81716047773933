import React, { useCallback } from 'react';
import Input, { InputProps } from '../UI/input';
import Range from '../UI/range';
import classNames from 'classnames';
import { RangeType } from '../UI/range/';

type RangeData = RangeType;

interface Props extends Pick<InputProps, 'label' | 'name' | 'id' | 'formattedValue'> {
    defaultValue: number;
    onChange: (fieldName: string, term: number) => void;
    rangeData: RangeData;
    customClasses?: string;
}

const InputWithSlider = ({
    onChange,
    defaultValue,
    name,
    id,
    label,
    rangeData,
    formattedValue,
    customClasses,
}: Props) => {
    const onChangeValue = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeTerm(event.target.value);
    }, []);
    const onChangeTermBySlider = useCallback((term: number) => {
        onChangeTerm(term.toString());
    }, []);
    const onChangeTerm = useCallback((value: string) => {
        const clearValue = value.replace(/\D/g, '');
        onChange(id, Number(clearValue));
    }, []);
    const classesNames = classNames(['relative flex-1', customClasses]);
    return (
        <div className={classesNames}>
            <Input
                label={label}
                value={defaultValue.toString()}
                onChange={onChangeValue}
                name={name}
                formattedValue={formattedValue}
                id={id}
            />
            <div className={'absolute right-[20px] left-[20px] -bottom-[7px]'}>
                <Range
                    value={Number(defaultValue) ?? 0}
                    onChangeValue={onChangeTermBySlider}
                    {...rangeData}
                />
            </div>
        </div>
    );
};
export default InputWithSlider;
