import React from 'react';
import { ILayout } from '../types';
import classes from './contentLayout.module.css';
import classNames from 'classnames';

const ContentLayout = ({ styles, customClasses, children }: ILayout) => {
	const contentLayoutClasses = classNames([
		classes.contentLayout,
		customClasses,
	]);
	return (
		<div style={{ ...styles }} className={contentLayoutClasses}>
			{children}
		</div>
	);
};
export default ContentLayout;
