import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IClientData } from '../../models/ClientData';
import { SmsService } from '../../services/sms.service';
import { ResponseErrorType } from '../../shared/types/API/ResponseSuccessType';
import { ClientVerificationType, VerifyType } from '../../shared/types/ClientVerificationType';
import { ISendSMSData, VerifyRequestsParams } from '../../shared/types/ISMS';
import { catchApiError } from '../../utils/catchError';
import { setLoanBlankId } from '../loan/LoanSlice';

type InitState = {
    clientData: IClientData;
    clientVerification: ClientVerificationType;
    isLoading: boolean;
    isSendSmsLoading: boolean;
};

const initState: InitState = {
    clientData: {
        iin: '',
        fio: '',
        email: '',
        firstName: '',
        lastName: '',
        middleName: '',
        phone: '',
    },
    clientVerification: {
        isPhoneConfirmed: false,
        sendCode: {
            hasAttempts: false,
            id: '',
        },
        verify: {
            hasAttempts: false,
            token: null,
            status: null,
        },
    },
    isLoading: false,
    isSendSmsLoading: false,
};

export const verifying = createAsyncThunk<VerifyType, VerifyRequestsParams>(
    'client/verify',
    async ({ code, verifyMessageId }, { rejectWithValue, dispatch }) => {
        try {
            return await SmsService.verify({ verifyMessageId: verifyMessageId, code: code });
        } catch (error) {
            catchApiError(error as ResponseErrorType, dispatch);
            return rejectWithValue(error);
        }
    }
);
export const sendSmsCode = createAsyncThunk<ISendSMSData, string>(
    'client/sendVerificationSMS',
    async (phone, { rejectWithValue, dispatch }) => {
        dispatch(setIsPhoneConfirmed(null));
        try {
            const data = await SmsService.sendSms(phone);
            if (data.token || data.blankId) {
                if (data.blankId) {
                    dispatch(setLoanBlankId(data.blankId));
                }
                if (data.token) {
                    dispatch(setVerifyToken(data.token));
                    dispatch(setIsPhoneConfirmed(true));
                }
            } else {
                dispatch(setIsPhoneConfirmed(false));
                dispatch(setVerifyData({ token: '', status: null, hasAttempts: true }));
            }
            return data;
        } catch (error) {
            catchApiError(error as ResponseErrorType, dispatch);
            return rejectWithValue(error);
        }
    }
);

export const clientSlice = createSlice({
    name: 'client',
    initialState: initState,
    reducers: {
        clearState() {
            return initState;
        },
        saveTempClientData(state, action: PayloadAction<{ fieldId: string; data: string }>) {
            const { fieldId, data } = action.payload;
            state.clientData[fieldId as keyof typeof state.clientData] = data;
        },
        saveClientData(state, action: PayloadAction<IClientData>) {
            state.clientData = action.payload;
        },
        setVerificationData(state, action: PayloadAction<ClientVerificationType>) {
            state.clientVerification = action.payload;
        },
        setVerifyData(state, action: PayloadAction<VerifyType>) {
            state.clientVerification.verify = action.payload;
        },
        setVerifyToken(state, action: PayloadAction<string>) {
            state.clientVerification.verify.token = action.payload;
        },
        setIsPhoneConfirmed(state, action: PayloadAction<boolean | null>) {
            state.clientVerification.isPhoneConfirmed = action.payload;
        },
        setIsClientVerificationLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
        clearClientStore(state) {
            state.clientData = initState.clientData;
            state.clientVerification = initState.clientVerification;
            state.isSendSmsLoading = initState.isSendSmsLoading;
            state.isLoading = initState.isLoading;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(verifying.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(verifying.fulfilled, (state, action) => {
            const { token } = action.payload;
            state.clientVerification.verify = action.payload;
            if (!token) {
                state.isLoading = false;
            } else {
                state.isLoading = false;
                state.clientVerification.isPhoneConfirmed = true;
            }
        });
        builder.addCase(sendSmsCode.pending, (state) => {
            state.isSendSmsLoading = true;
            state.clientVerification.verify = initState.clientVerification.verify;
        });
        builder.addCase(sendSmsCode.fulfilled, (state, action) => {
            state.isSendSmsLoading = false;
            state.clientVerification.sendCode = {
                hasAttempts: action.payload.hasAttempts,
                id: action.payload.id ?? '',
            };
        });
        builder.addCase(sendSmsCode.rejected, (state) => {
            state.isSendSmsLoading = false;
        });
    },
});

export const {
    clearClientStore,
    saveClientData,
    setVerificationData,
    setVerifyData,
    setIsClientVerificationLoading,
    setVerifyToken,
    setIsPhoneConfirmed,
    saveTempClientData,
} = clientSlice.actions;
export default clientSlice.reducer;
