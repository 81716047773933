import { useMemo } from 'react';
import { useAppSelector } from '../../hooks/useAppSelector';
import { ScoringStatuses } from '../../models';
import { Bank } from '../../models/Bank';
import { ScoringBank } from '../../models/ScoringBank';
import crossSvg from './../../assets/images/icons/failure-lg.svg';
import classes from './banks.module.css';
import BankItem from './Item';

interface BanksProps {
    banks: ScoringBank[];
}

const AllCancel = () => {
    return (
        <div className={'flex justify-center flex-col items-center'}>
            <img className={'w-[250px] h-[250px]'} src={crossSvg} alt="Cross svg" />
            <p className={'mt-8 text-center font-bold text-[36px] msm:text-[28px]'}>
                Все банки отказали <br /> по данной заявке :(
            </p>
        </div>
    );
};
const Banks = ({ banks }: BanksProps) => {
    const { loanBlankId } = useAppSelector((state) => state.loanStore);
    const clickedButtons = useAppSelector((state) => {
        return state.scoringStore.clickedButtons;
    });

    const isAllCancel = useMemo(() => {
        return banks.every(
            (item) =>
                item.status === ScoringStatuses.ERROR || item.status === ScoringStatuses.REJECTED
        );
    }, [banks]);
    const oneIsApproved = useMemo(() => {
        return banks.some((item) => item.status === ScoringStatuses.APPROVED);
    }, [banks]);
    return (
        <div className={'flex flex-1 flex-col'}>
            <div className={classes.banks}>
                {banks.map((item) => {
                    // if (!item.offer) return null;
                    const isDisabled = Object.keys(clickedButtons).length !== 0;
                    const isRequesting = !!clickedButtons[item?.offer?.id ?? null];
                    const bank: Bank = {
                        bankId: item.id,
                        term: item?.offer?.length ?? null,
                        name: item.name,
                        status: item.status,
                        payment: item?.offer?.monthPayment ?? null,
                        amount: item?.offer?.sum ?? null,
                        icon: item.logoUrl,
                        offerId: item?.offer?.id ?? null,
                        redirectUrl: item?.offer?.redirectUrl ?? '',
                    };
                    return (
                        <BankItem
                            key={item.id}
                            isDisabled={isDisabled}
                            bank={bank}
                            loanBlankId={loanBlankId}
                            isRequesting={isRequesting}
                        />
                    );
                })}
            </div>
        </div>
    );
};
export default Banks;
