import React, { ReactElement, useCallback } from 'react';
import type { StepsProps, StepType } from './types';
import Step from './Step';
import classes from './steps.module.css';
import classNames from 'classnames';

const Steps: StepsProps = ({
	children,
	currentStepTitle,
	currentStep,
	className,
	stepsClassName,
	onChange,
}) => {
	const onClickHandler = useCallback((stepNumber: number) => {
		onChange(stepNumber);
	}, []);

	const stepperClass = classNames(classes.stepper, className);
	const stepperStepsClass = classNames(
		classes.stepper__steps,
		stepsClassName
	);
	return (
		<div className={stepperClass}>
			<div className={stepperStepsClass}>
				{React.Children.map(children, (c, index) => {
					if (c) {
						const child = c as ReactElement<StepType>;
						const status =
							currentStep === index + 1 ? 'process' : 'wait';
						const el = React.cloneElement(child, {
							onClick: () => onClickHandler(index + 1),
							status: child.props.status || status,
							stepNumber: index + 1,
						});
						return el;
					}
					return null;
				})}
			</div>
			<div className={classes.stepper__title}>{currentStepTitle}</div>
		</div>
	);
};
Steps.Step = Step;
export default Steps;
