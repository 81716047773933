import React from 'react';
import { RadioSliderItem } from './RadioSliderItem';
import { IRadioSliderItem } from './types';

interface IProps<T> {
	slides: IRadioSliderItem<T>[];
	selectedSlide: IRadioSliderItem<T>;
	classes?: string;
	onSelect: (slide: IRadioSliderItem<T>) => void;
}

export const RadioSlider = <Type extends string>({
	slides,
	selectedSlide,
	classes,
	onSelect,
}: IProps<Type>) => {
	const onSelectSlideHandler = (slide: IRadioSliderItem<Type>) => {
		if (selectedSlide.value !== slide.value) {
			onSelect(slide);
		}
	};
	const slidesElement = slides.map((item, index) => {
		return (
			<RadioSliderItem
				disabled={item.disabled}
				key={item.value}
				isFirst={index === 0}
				isLast={index === slides.length - 1}
				isSelected={selectedSlide.value === item.value}
				onSelect={() => onSelectSlideHandler(item)}
				{...item}
			/>
		);
	});
	return <div className={`flex flex-1 w-full ${classes}`}>{slidesElement}</div>;
};
