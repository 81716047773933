import React from 'react';
import classes from './radioSlider.module.css';
import { IRadioSliderItem } from './types';
import classNames from 'classnames';

interface IProps<T> extends IRadioSliderItem<T> {
	onSelect: () => void;
	isSelected: boolean;
	isFirst: boolean;
	isLast: boolean;
	disabled?: boolean;
}

export const RadioSliderItem = <T,>({
	onSelect,
	label,
	isSelected,
	isFirst,
	isLast,
	disabled = false,
}: IProps<T>) => {
	const onSelectHandler = () => {
		if (!disabled) onSelect();
	};
	return (
		<div
			onClick={onSelectHandler}
			className={classNames(classes.sliderItem, {
				[classes.sliderItem_selected]: isSelected,
				[classes.sliderItem_first]: isFirst,
				[classes.sliderItem_last]: isLast,
				'!cursor-not-allowed bg-primaryGrey50': disabled,
				'cursor-pointer': !disabled,
			})}>
			<span className={`${isSelected ? 'text-white' : ''}`}>{label}</span>
		</div>
	);
};
