import React, { useEffect, useState } from 'react';
import { ContentLayout } from '../layouts';
import { ClientData, PhoneVerification } from '../components';
import { IClientData } from '../models/ClientData';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { saveClientData, sendSmsCode } from '../store/client/ClientSlice';
import { useAppSelector } from '../hooks/useAppSelector';
import { createLoan } from '../store/loan/LoanSlice';
import { setActiveStep } from '../store/StepperSlice';
import { catchApiError } from '../utils/catchError';

const SecondStep = () => {
    const [tab, setTab] = useState<'clientData' | 'phoneConfirmation'>('clientData');
    const { clientVerification, isSendSmsLoading } = useAppSelector((state) => state.clientStore);
    const { isBlankLoading } = useAppSelector((state) => state.loanStore);
    const [isLoading, setIsLoading] = useState(isSendSmsLoading || isBlankLoading);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isSendSmsLoading || isBlankLoading) setIsLoading(true);
        else if (!isSendSmsLoading && !isBlankLoading) setIsLoading(false);
    }, [isSendSmsLoading, isBlankLoading]);

    const onSuccessVerifying = (token: string, clientData?: IClientData) => {
        if (tab !== 'clientData') setTab('clientData');
        dispatch(createLoan({ token: token, clientData: clientData }))
            .unwrap()
            .then((loanBlankId) => {
                if (loanBlankId) {
                    dispatch(setActiveStep(3));
                }
            })
            .catch((err) => {
                catchApiError(err, dispatch);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const onConfirmUserData = (clientData: IClientData) => {
        if (!clientData) return null;
        dispatch(saveClientData(clientData));
        if (!clientVerification.isPhoneConfirmed) {
            dispatch(sendSmsCode(clientData.phone))
                .unwrap()
                .then(({ token }) => {
                    if (!token) {
                        setIsLoading(false);
                        setTab('phoneConfirmation');
                    }
                })
                .catch(() => {
                    setIsLoading(false);
                });
        } else {
            if (!clientVerification.verify.token) {
                catchApiError(
                    {
                        detail: 'Отсутвует токен верификации номера',
                        title: 'ошибка',
                        status: 500,
                    },
                    dispatch
                );
                return;
            }
            onSuccessVerifying(clientVerification.verify.token, clientData);
        }
    };
    return (
        <ContentLayout>
            {tab === 'clientData' ? (
                <ClientData
                    isPhoneConfirmed={clientVerification.isPhoneConfirmed ?? false}
                    onConfirmUserData={onConfirmUserData}
                    isLoading={isLoading}
                    nextButtonText={!clientVerification.isPhoneConfirmed ? 'Подтвердить' : 'Далее'}
                />
            ) : null}
            {tab === 'phoneConfirmation' ? (
                <PhoneVerification
                    onSuccessVerifying={() => {
                        setTab('clientData');
                    }}
                    onChangeClientDataClickHandler={() => setTab('clientData')}
                />
            ) : null}
        </ContentLayout>
    );
};
export default SecondStep;
