import { AxiosError, AxiosRequestConfig } from 'axios';
import { QRCode } from '../models';
import { ScoringResponse } from '../models/loan/ScoringResponse';
import { CreateLoanRequestData } from '../shared/types/API/CreateLoanRequestData';
import { ResponseErrorType } from '../shared/types/API/ResponseSuccessType';
import axios, { ErrorController } from './Axios';

export abstract class LoanService {
    static scoringResult(
        loanBlankId: string,
        abortController?: AbortController
    ): Promise<ScoringResponse> {
        const config: AxiosRequestConfig = {};
        if (abortController) {
            config['signal'] = abortController.signal;
        }
        return axios
            .get<ScoringResponse>('/scoring/result/' + loanBlankId + '/small', config)
            .then((res) => {
                // console.log('res', res);
                // const result: ScoringResponse = {
                //     data: res.data,
                //     tokens: {
                //         offersjwt: res.headers['offersjwt'] ?? '',
                //         scoringjwt: res.headers['scoringjwt'] ?? '',
                //     },
                // };
                return res.data;
            })
            .catch((err) => {
                return ErrorController(err);
            });
    }
    static startScoring(loanBlankId: string): Promise<unknown> {
        return axios
            .post<unknown>('/scoring/start/' + loanBlankId)
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return ErrorController(err);
            });
    }

    static createLoan({
        loanBlankId,
        cart,
        configuration,
        retailPointId,
        personalData,
        token,
        bankProducts,
    }: CreateLoanRequestData): Promise<{ loanBlankId: string }> {
        return axios
            .post<{ loanBlankId: string }>('/loan-blank/create/' + token, {
                loanBlankId: loanBlankId || null,
                configuration: { ...configuration, sum: configuration.sum.toString() },
                personalData: personalData,
                cart: cart,
                bankProducts: bankProducts,
                retailPointId: retailPointId,
            })
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                return ErrorController(error);
            });
    }

    static selectOffer(offerId: string) {
        return axios
            .post('/scoring/select-offer/' + offerId)
            .then((res) => {
                return res.data;
            })
            .catch((error: AxiosError<ResponseErrorType>) => {
                return ErrorController(error);
            });
    }
    static getQrCode(offerId: string): Promise<QRCode> {
        return axios
            .post<QRCode>(`/widget/qr/${offerId}`)
            .then((res) => {
                return res.data;
            })
            .catch((error) => {
                return ErrorController(error);
            });
    }
}
