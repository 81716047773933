import React, { useEffect, useState } from 'react';
import { useTimer } from 'use-timer';
import { overrideTailwindClasses } from 'tailwind-override';
import classNames from 'classnames';
import classes from './confirmationCode.module.css';
import { secondsToFormatTime } from '../../shared/utils';
type IProps = {
    isDone: boolean;
    id: string;
    onSendCodeAgain: () => void;
    tailwindClasses?: string;
    needResetTimer: boolean;
    onResetTimer: () => void;
};

export const ResendPhoneVerificationButton = ({
    tailwindClasses,
    isDone,
    id,
    onSendCodeAgain,
    needResetTimer,
    onResetTimer,
}: IProps) => {
    const { time, start, reset, pause } = useTimer({
        initialTime: 90,
        timerType: 'DECREMENTAL',
    });
    const [isActive, setIsActive] = useState(true);
    const sendCodeAgain = () => {
        onSendCodeAgain();
    };

    useEffect(() => {
        if (id) {
            reset();
            start();
        }
    }, [id]);
    useEffect(() => {
        if (needResetTimer) {
            reset();
            start();
            setIsActive(false);
            onResetTimer();
        }
    }, [needResetTimer]);
    useEffect(() => {
        if (isDone) {
            stop();
            setIsActive(true);
        }
    }, [isDone]);
    useEffect(() => {
        if (time <= 0) {
            pause();
            setIsActive(true);
        } else if (isActive && !isDone) setIsActive(false);
    }, [time]);

    return (
        <button
            disabled={!isActive}
            className={overrideTailwindClasses(
                classNames([
                    classes.resendCode_button,
                    `${
                        !isActive
                            ? 'border-primaryGrey text-primaryGrey'
                            : 'border-primaryOrange text-primaryOrange'
                    } ${tailwindClasses}`,
                ])
            )}
            onClick={() => sendCodeAgain()}>
            {!isActive
                ? `Отправить код повторно через ${secondsToFormatTime(time || 0)}`
                : 'Отправить код повторно'}
        </button>
    );
};
