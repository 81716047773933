export const queryStringToObject = <T>(queryString: string): T | null => {
    if (!queryString) return null;
    const splittedQuery = queryString.split('&');
    const result: any = {};
    for (const param of splittedQuery) {
        if (param === '') continue;
        const [key, value] = param.split('=');
        if (isArray(key)) {
            const data = parseArrayItemKey(key);
            if (result[data.arrayKey]) {
                if (data.objectDeep.length) {
                    result[data.arrayKey][data.index] = {
                        ...result[data.arrayKey][data.index],
                        ...generateObject(data.objectDeep, value),
                    };
                } else {
                    result[data.arrayKey][data.index] = value.replace(/%20/g, ' ');
                }
            } else {
                result[data.arrayKey] = [];
                if (data.objectDeep.length) {
                    result[data.arrayKey] = [generateObject(data.objectDeep, value)];
                } else {
                    result[data.arrayKey] = [value.replace(/%20/g, ' ')];
                }
            }
        } else {
            result[key] = value.replace(/%20/g, ' ');
        }
    }
    return result as T;
};
const generateObject = (strings: string[], value: string) => {
    let numberOfBrackets = 1;
    let jsonString = '{';
    strings.forEach((item, index) => {
        if (index !== strings.length - 1) {
            numberOfBrackets++;
            jsonString += `"${item}":{`;
        } else {
            jsonString += `"${item}": "${value.replace(/%20/g, ' ')}"`;
        }
    });
    while (numberOfBrackets !== 0) {
        jsonString += '}';
        numberOfBrackets--;
    }
    return JSON.parse(jsonString);
};

const isArray = (key: string) => {
    return /[[\]]/.exec(key);
};

const parseArrayItemKey = (key: string) => {
    const [arrayKey, index, ...objectDeep] = key.split('[').map((item) => item.replace(']', ''));
    return { arrayKey, index: Number(index), objectDeep: objectDeep };
};
