import React, { useEffect, useRef, useState } from 'react';
import PCMobileSlider from '../components/PCMobileSlider/PCMobileSlider';
import Button from '../components/UI/button/Button';
import { Loader } from '../components/UI/loader/Loader';
import { IRadioSliderItem } from '../components/UI/radioSlider/types';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import { ContentLayout } from '../layouts';
import { ScoringStatuses } from '../models';
import { LoanService } from '../services/loan.service';
import { BankIds } from '../shared/constants/BankIds';
import { setSelectedDecision } from '../store/scoring/scoring';
import { setActiveStepTitle, setDisabledSteps } from '../store/StepperSlice';
import { catchApiError } from '../utils/catchError';
import popupWindowStore from '../utils/popupWindowStore';
import crossSvg from './../assets/images/icons/failure-lg.svg';
import tickSvg from './../assets/images/icons/tick.svg';

const slides: IRadioSliderItem<string>[] = [
    {
        label: 'С телефона',
        value: 'mobile',
    },
    {
        label: 'С компьютера',
        value: 'PC',
    },
];
type ConfirmWithQrWithLinkProps = {
    offerId: string;
};
const ConfirmWithQrWithLink = ({ offerId }: ConfirmWithQrWithLinkProps) => {
    const dispatch = useAppDispatch();
    const [selectedSlide, setSelectedSlide] = useState(slides[0]);
    const [isQrLoading, setIsQrLoading] = useState(false);
    const [qr, setQr] = useState('');
    const [link, setLink] = useState('');
    const onClickLinkHandler = () => {
        window.open(link, 'blank');
    };

    useEffect(() => {
        // LoanService.getQrCode()
        setIsQrLoading(true);
        LoanService.getQrCode(offerId)
            .then((res) => {
                setQr(res.qr);
                setLink(res.url);
            })
            .catch((err) => {
                setQr('');
                setLink('');
                catchApiError(err, dispatch);
            })
            .finally(() => {
                setIsQrLoading(false);
            });
    }, []);

    return (
        <div className={'w-full mt-4'}>
            <PCMobileSlider
                customClasses={'w-1/2  sm:w-full mx-auto'}
                slides={slides}
                onChangeSlide={setSelectedSlide}
                defaultSlide={selectedSlide}
            />
            {isQrLoading ? (
                <div className={'flex justify-center'}>
                    <Loader styles={{ marginTop: '14px', marginBottom: '14px' }} />
                </div>
            ) : null}
            {!isQrLoading && qr && link ? (
                <div className={' flex justify-center items-center sm:flex-col mt-4'}>
                    {selectedSlide.value === 'mobile' ? (
                        <>
                            <img
                                src={qr}
                                className={'w-[300px] h-[300px] shadow-xl rounded-2xl'}
                                alt="qr"
                            />
                            <p className={'mt-4 w-1/2  sm:w-full ml-4 sm:ml-0 text-center'}>
                                Если вы ведете оформление с ПК или ноутбука, сканируйте QR при
                                помощи мобильного устройства, для продолжения оформления кредита
                            </p>
                        </>
                    ) : (
                        <div className={'flex flex-col items-center'}>
                            <Button
                                type={'primary'}
                                className={'text-white mt-8'}
                                onClickHandler={onClickLinkHandler}>
                                Перейти в ПО банка
                            </Button>
                            <p className={'mt-4 w-1/2  sm:w-full  text-center'}>
                                Перейдите по ссылке для продолжения оформления кредита , если вы
                                оформляете заявку с мобильного устройства
                            </p>
                        </div>
                    )}
                </div>
            ) : null}
            {!isQrLoading && !qr && !link ? (
                <div className={'text-center mt-4'}>
                    <p>Ошибка получения данных, попробуйте позднее</p>
                </div>
            ) : null}
        </div>
    );
};
type ConfirmWithAutoSentLinkProps = {
    redirectUrl?: string;
};
const ConfirmWithAutoSentLink = ({ redirectUrl }: ConfirmWithAutoSentLinkProps) => {
    const { phone } = useAppSelector((state) => state.clientStore.clientData);
    const openedWindow = popupWindowStore.window;
    useEffect(() => {
        if (redirectUrl) {
            if (openedWindow && openedWindow.document && openedWindow.document.location) {
                openedWindow.document.location = redirectUrl;
            } else {
                window.open(redirectUrl, 'blank');
            }
        }
    }, []);

    return (
        <div className={'flex flex-col justify-center items-center'}>
            <img
                className={'w-[300px] h-[300px] msm:w-[200px] msm:h-[200px]'}
                src={tickSvg}
                alt={'tick'}
            />
            <p className={'mt-4 text-center'}>
                Ссылка для авторизации кредита была отправлена по СМС на <strong>{phone}</strong>.
                Перейдите по ней для продолжения оформления.
            </p>
            <Loader styles={{ marginTop: '16px' }} />
        </div>
    );
};

const SuccessFullyApproved: React.FC = () => {
    const onCompleteClickHandler = () => {
        window.parent.postMessage('close');
        alert('завершение заявки');
    };
    return (
        <div className={'flex flex-col justify-center items-center'}>
            <img className={'w-[150px] h-[150px]'} src={tickSvg} alt={'tick'} />
            <h1 className={'text-[36px] msm:text-[28px] text-center font-bold'}>
                Заявка подтверждена :)
            </h1>
            <p className={'text-[24px] msm:text-[20px] text-center max-w-[400px] mt-4'}>
                Ожидайтие, с вами свяжется сотрудник магазина.
            </p>
            <Button
                type={'primary'}
                className={'text-white mt-[120px] msm:mt-8 font-bold text-[26px] msm:text-[18px]'}
                onClickHandler={onCompleteClickHandler}>
                Завершить оформление
            </Button>
        </div>
    );
};

const SuccessFullyError = () => {
    const onCompleteClickHandler = () => {
        alert('завершение заявки');
    };
    return (
        <div className={'flex flex-col justify-center items-center'}>
            <img className={'w-[150px] h-[150px]'} src={crossSvg} alt={'tick'} />
            <h1 className={'text-[36px] msm:text-[28px] font-bold'}>Заявка отклонена :(</h1>
            <p className={'text-[24px] msm:text-[20px] text-center mt-4'}>
                Произошла ошибка при оформлении заявки, проверьте введенные данные или повторите
                попытку позднее.
            </p>
            <Button
                type={'primary'}
                className={
                    'text-white mt-[40px] msm:mt-8 msm:text-[18px] font-bold text-[26px] bg-red-700'
                }
                onClickHandler={onCompleteClickHandler}>
                Завершить оформление
            </Button>
        </div>
    );
};
const FinalStep = () => {
    const { selectedDecision } = useAppSelector((state) => state.scoringStore);
    const { loanBlankId } = useAppSelector((state) => state.loanStore);
    const dispatch = useAppDispatch();
    const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
    useEffect(() => {
        dispatch(setDisabledSteps([1, 2, 3]));
    }, []);
    const checkStatus = () => {
        let needStop = false;
        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
        LoanService.scoringResult(loanBlankId)
            .then((res) => {
                const data = res;
                const currentOffer = data.items.find(
                    (item) => item?.offer?.id === selectedDecision?.offerId
                );
                if (currentOffer && selectedDecision) {
                    if (
                        currentOffer.status === ScoringStatuses.SIGNED ||
                        currentOffer.status === ScoringStatuses.ERROR
                    ) {
                        needStop = true;
                    }
                    dispatch(
                        setSelectedDecision({ ...selectedDecision, status: currentOffer.status })
                    );
                }
            })
            .catch((err) => {
                catchApiError(err, dispatch);
            })
            .finally(() => {
                if (!needStop) {
                    timer.current = setTimeout(() => {
                        checkStatus();
                    }, 3000);
                } else {
                    if (timer.current) {
                        clearTimeout(timer.current);
                        timer.current = null;
                    }
                }
            });
    };
    useEffect(() => {
        checkStatus();
        dispatch(setActiveStepTitle('Оформите кредит/рассрочку'));
        return () => {
            if (timer.current) {
                clearTimeout(timer.current);
                timer.current = null;
            }
        };
    }, []);

    return (
        <ContentLayout
            styles={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
            }}>
            {selectedDecision?.status === ScoringStatuses.SIGNED ? <SuccessFullyApproved /> : null}
            {selectedDecision?.status === ScoringStatuses.ERROR ? <SuccessFullyError /> : null}
            {selectedDecision?.bankId === BankIds.freedomBankId &&
            selectedDecision.status !== ScoringStatuses.ERROR &&
            selectedDecision.status !== ScoringStatuses.SIGNED ? (
                <ConfirmWithAutoSentLink redirectUrl={selectedDecision.redirectUrl} />
            ) : null}
            {selectedDecision?.bankId === BankIds.eurasianBankId &&
            selectedDecision.status !== ScoringStatuses.ERROR &&
            selectedDecision.status !== ScoringStatuses.SIGNED ? (
                <ConfirmWithQrWithLink offerId={selectedDecision.offerId} />
            ) : null}
        </ContentLayout>
    );
};
export default FinalStep;
