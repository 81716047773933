import React, { memo } from 'react';
import Slider from 'rc-slider';
import './range.css';
import { RangeProps } from './types';

const Range = ({
	value,
	defaultValue,
	onChangeValue,
	disabled,
	...props
}: RangeProps) => {
	const onChange = (value: number | number[]) => {
		onChangeValue(value as number);
	};
	if (props.step === null) {
		const ObjectKeys = Object.keys(props.marks);
		const minKey = Number(ObjectKeys[0]);
		const maxKey = Number(ObjectKeys[ObjectKeys.length - 1]);
		return (
			<Slider
				min={minKey}
				max={maxKey}
				disabled={disabled}
				value={value}
				onChange={onChange}
				marks={props.marks}
				step={props.step}
				railStyle={{
					height: '2px',
					width: '100%',
				}}
				trackStyle={{
					backgroundColor: '#E86F2D',
					height: '2px',
				}}
				dotStyle={{
					backgroundColor: '#E86F2D',
					borderColor: '#E86F2D',
					bottom: '-1px',
					cursor: 'pointer',
					display: 'none',
				}}
				handleStyle={{
					backgroundColor: '#E86F2D',
					borderColor: '#E86F2D',
					border: 'none',
					boxShadow: 'none',
					opacity: 1,
					marginTop: '-6px',
				}}
			/>
		);
	} else {
		return (
			<Slider
				disabled={disabled}
				value={value}
				onChange={onChange}
				min={props.min}
				max={props.max}
				defaultValue={defaultValue}
				step={props.step}
			/>
		);
	}
};
export default memo(Range);
