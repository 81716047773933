import React from 'react';
import { StepProps, StepType } from './types';
import classes from './steps.module.css';
import classNames from 'classnames';

const Step = (props: StepProps) => {
    const {
        onClick,
        status = 'wait',
        disabled,
        stepNumber,
        buttonClassName,
        progressClassName,
    } = props as StepType;
    const onClickHandler = () => {
        if (!disabled) onClick();
    };
    const progressClasses = classNames(classes.step__progress, progressClassName, {
        [classes.step__completed]: status === 'complete',
    });
    const buttonClasses = classNames(classes.step__button, buttonClassName, {
        [classes.step__button_active]: status === 'process',
    });
    return (
        <div className={classes.step}>
            <button className={buttonClasses} onClick={onClickHandler} disabled={disabled}>
                {stepNumber}
            </button>
            <div className={progressClasses} />
        </div>
    );
};
export default Step;
