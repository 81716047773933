export enum ScoringStatuses {
    'NEW' = 'NEW',
    'SCORING' = 'SCORING',
    'DRAFT' = 'DRAFT',
    'APPROVED' = 'APPROVED',
    'REJECTED' = 'REJECTED',
    'ERROR' = 'ERROR',
    'CUSTOMER_APPROVED' = 'CUSTOMER_APPROVED',
    'CUSTOMER_CANCELLED' = 'CUSTOMER_CANCELLED',
    'SIGNED' = 'SIGNED',
    'DELIVERED' = 'DELIVERED',
    'CLOSED' = 'CLOSED',
    'REFUND' = 'REFUND',
}
