import React from 'react';
import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { Bank } from '../../../models/Bank';
import { ScoringStatuses } from '../../../models/loan/ScoringStatuses';
import { LoanService } from '../../../services/loan.service';
import { BankIds } from '../../../shared/constants/BankIds';
import { moneyFormat } from '../../../shared/utils';
import {
    removeClickedButton,
    setClickedButton,
    setSelectedDecision,
} from '../../../store/scoring/scoring';
import { setActiveStep } from '../../../store/StepperSlice';
import { catchApiError } from '../../../utils/catchError';
import popupWindowStore from '../../../utils/popupWindowStore';
import Button from '../../UI/button/Button';
import { Loader } from '../../UI/loader/Loader';
import { SkeletonRow } from '../../UI/skeleton/SkeletonRows';
import classes from './bank.module.css';
import BankHeader from './bankHeader';

interface BankItemProps {
    bank: Bank;
    loanBlankId: string;
    isRequesting: boolean;
    isDisabled: boolean;
}

const BankItem: React.FC<BankItemProps> = ({ bank, isRequesting, isDisabled }: BankItemProps) => {
    const dispatch = useAppDispatch();
    const onChooseBankClickHandler = () => {
        dispatch(setClickedButton({ offerId: bank.offerId, buttonId: 'SELECT' }));
        //TODO избегаем блокировки модалки
        if (bank.bankId === BankIds.freedomBankId) {
            const win = window.open('', 'blank');
            popupWindowStore.window = win;
        }
        if (!bank.offerId) return;
        LoanService.selectOffer(bank.offerId)
            .then(() => {
                dispatch(setActiveStep(4));
                dispatch(setSelectedDecision(bank));
            })
            .catch((error) => {
                popupWindowStore.window = null;
                dispatch(removeClickedButton({ offerId: bank.offerId }));
                catchApiError(error, dispatch);
            });
    };
    const renderContent = () => {
        switch (bank.status.toUpperCase()) {
            case ScoringStatuses.ERROR:
                return <div className={classes.bank__content_error}>Нет ответа от банка</div>;
            default:
                return (
                    <ul className={classes.bank__content_list}>
                        <li className={classes.bank__content_item}>
                            <span className={classes.bank__content_item_label}>Сумма</span>
                            {bank.status.toUpperCase() === ScoringStatuses.SCORING ? (
                                <SkeletonRow number={1} />
                            ) : (
                                <span className={classes.bank__content_item_value}>
                                    {moneyFormat(bank.amount)}
                                </span>
                            )}
                        </li>
                        <li className={classes.bank__content_item}>
                            <span className={classes.bank__content_item_label}>Срок</span>
                            {bank.status.toUpperCase() === ScoringStatuses.SCORING ? (
                                <SkeletonRow number={1} />
                            ) : (
                                <span className={classes.bank__content_item_value}>
                                    {bank.term} мес.
                                </span>
                            )}
                        </li>
                        <li className={classes.bank__content_item}>
                            <span className={classes.bank__content_item_label}>
                                Платеж (в мес.)
                            </span>
                            {bank.status.toUpperCase() === ScoringStatuses.SCORING ? (
                                <SkeletonRow number={1} />
                            ) : (
                                <span className={classes.bank__content_item_value}>
                                    {moneyFormat(bank.payment)}
                                </span>
                            )}
                        </li>
                    </ul>
                );
        }
    };
    return (
        <div className={classes.bank}>
            <BankHeader icon={bank.icon} name={bank.name} />
            <div className={classes.bank__content}>{renderContent()}</div>
            <div className={classes.bank__footer}>
                {bank.status.toUpperCase() === 'APPROVED' ? (
                    <Button
                        disabled={isDisabled}
                        className={classes.bank__button}
                        type={'primary'}
                        block={true}
                        onClickHandler={onChooseBankClickHandler}>
                        <span className={classes.bank__button_text}>Выбрать</span>
                        {isRequesting ? (
                            <Loader styles={{ marginLeft: '14px' }} color={'white'} />
                        ) : null}
                    </Button>
                ) : null}
            </div>
        </div>
    );
};
export default BankItem;
