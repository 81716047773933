import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ResponseErrorType } from '../../shared/types/API/ResponseSuccessType';

type InitState = {
    toasts: ({ id: string } & ResponseErrorType)[];
};

const state: InitState = {
    toasts: [],
};

const toastSlice = createSlice({
    name: 'toast',
    initialState: state,
    reducers: {
        clearToastStore: (state) => {
            return { ...state, toasts: [] };
        },
        addToast: (state, action: PayloadAction<{ id: string } & ResponseErrorType>) => {
            state.toasts.push(action.payload);
        },
        removeToast: (state, action: PayloadAction<string>) => {
            state.toasts.splice(
                state.toasts.findIndex((toast) => toast.id === action.payload),
                1
            );
        },
    },
});

export const { addToast, removeToast, clearToastStore } = toastSlice.actions;

export default toastSlice.reducer;
