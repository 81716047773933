import React from 'react';
import classes from '../bank.module.css';

interface Props {
	icon: string;
	name: string;
}
const BankHeader = ({ icon, name }: Props) => {
	return (
		<div className={classes.bank__header}>
			<img className={classes.bank__header_logo} src={icon} alt={name} />
			<span className={classes.bank__header_name}>{name}</span>
		</div>
	);
};
export default BankHeader;
