import { AxiosError } from 'axios';
import { ResponseErrorType } from '../shared/types/API/ResponseSuccessType';
import { ISendSMSData, VerifyData, VerifyRequestsParams } from '../shared/types/ISMS';
import axios, { ErrorController } from './Axios';

export abstract class SmsService {
    static sendSms(phone: string): Promise<ISendSMSData> {
        return axios
            .post('/verification/send-code', { phone: phone })
            .then((res) => {
                return res.data;
            })
            .catch((err) => {
                return ErrorController(err);
            });
    }
    static verify({ verifyMessageId, code }: VerifyRequestsParams): Promise<VerifyData> {
        return axios
            .patch<VerifyData>('/verification/verify', {
                verifyMessageId,
                code: Number(code),
            })
            .then((response) => {
                return response.data;
            })
            .catch((error: AxiosError<ResponseErrorType>) => {
                return ErrorController(error);
            });
    }
}
