import React, { useEffect, useRef, useState } from 'react';
import { CodeCaret } from './CodeCaret';
import classes from './confirmationCode.module.css';
import { KeyboardKeys } from './KeyboardKeys';
import classNames from 'classnames';

type Props = {
    autoFocus?: boolean;
    disabled?: boolean;
    length?: number;
    containerClassName?: string;
    inputClassName?: string;
    isPassword?: boolean;
    onChange: (res: string) => void;
    withError?: boolean;
    onFocus?: () => void;
};

export const Code = ({
    autoFocus,
    containerClassName,
    inputClassName,
    isPassword,
    disabled,
    onChange,
    withError,
    length = 4,
    onFocus,
}: Props) => {
    const [activeInputIndex, setActiveInputIndex] = useState(-1);
    const inputsRef = useRef<Array<HTMLInputElement>>([]);
    const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        const { key } = event;
        const target = event.target as HTMLInputElement;
        switch (key) {
            case KeyboardKeys.backspace:
                if (target.value === '') {
                    if (activeInputIndex > 0) {
                        goToPreviousInput(true);
                        event.preventDefault();
                    }
                } else target.value = '';
                sendCode();
                break;
            case KeyboardKeys.leftArrow:
                goToPreviousInput();
                break;
            case KeyboardKeys.rightArrow:
                goToNextInput();
        }
    };

    useEffect(() => {
        inputsRef.current.forEach((input) => (input.value = ''));
    }, []);

    useEffect(() => {
        if (autoFocus) inputsRef.current[0].focus();
    }, []);
    useEffect(() => {
        if (disabled) {
            inputsRef.current[activeInputIndex]?.blur();
            setActiveInputIndex(-1);
        }
    }, [disabled]);
    const goToNextInput = (withClear?: boolean) => {
        const nextInput = inputsRef.current[activeInputIndex + 1];
        if (nextInput) {
            if (withClear) nextInput.value = '';
            nextInput.focus();
        }
    };
    const goToPreviousInput = (withClear?: boolean) => {
        const previousInput = inputsRef.current[activeInputIndex - 1];
        if (previousInput) {
            previousInput.focus();
            if (withClear) previousInput.value = '';
        }
    };
    const onChangeEventHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.replace(/\d/g, '').length !== 0) {
            event.target.value = event.target.value.replace(/\D/g, '')[0] ?? '';
            return;
        }
        if (event.target.value.length > 1) {
            event.target.value = event.target.value[0];
            goToNextInput(true);
        } else {
            goToNextInput(true);
        }
        sendCode();
    };
    const sendCode = () => {
        const code = inputsRef.current.reduce<string>((acc, currentValue) => {
            return acc + currentValue.value;
        }, '');
        onChange(code);
    };
    const onFocusEventHandler = (event: React.FocusEvent<HTMLInputElement>) => {
        const indexOfCurrentInput = inputsRef.current.findIndex((item) => item === event.target);
        if (indexOfCurrentInput > -1) {
            setActiveInputIndex(indexOfCurrentInput);
        }
        if (withError) {
            inputsRef.current.forEach((item) => (item.value = ''));
        }
        onFocus && onFocus();
    };
    const onBlurHandler = () => {
        setActiveInputIndex(-1);
    };
    const onClickHandler = (index: number) => {
        inputsRef.current[index].setSelectionRange(0, 0);
    };
    const inputs = new Array(length).fill('').map((_, index) => {
        return (
            <div key={index} className={'relative'}>
                <input
                    disabled={disabled}
                    ref={(ref) => {
                        inputsRef.current[index] = ref as HTMLInputElement;
                    }}
                    onClick={() => onClickHandler(index)}
                    type={isPassword ? 'password' : 'text'}
                    onBlur={onBlurHandler}
                    onFocus={onFocusEventHandler}
                    onChange={onChangeEventHandler}
                    onKeyDown={onKeyDown}
                    inputMode={'numeric'}
                    key={index}
                    className={classNames([
                        classes.code_input,
                        inputClassName,
                        withError ? classes.code_input_error : '',
                    ])}
                />
                {activeInputIndex !== -1 && activeInputIndex === index && <CodeCaret />}
            </div>
        );
    });
    return <div className={classNames([classes.code, containerClassName])}>{inputs}</div>;
};
