import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App, { EntryPointType } from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './store';
import {exitFunction} from './components/exit/Exit';
import Axios from './services/Axios';

window.vantaKZWidget = {
    onInitWidgetHandler: null,
    ReactDOMRoot: null,
    rootElement: null,
    onOpen : undefined,
    onClose : undefined
};

const widget = window.vantaKZWidget;

widget.onInitWidgetHandler = (data: EntryPointType) => {
    let apiKey : string | null;

    if (data.apiKey){
        apiKey = data.apiKey;
    } else {
        const scriptTag = document.getElementById('VantaKzWidget-script');
        apiKey = scriptTag?.getAttribute('data-api-key') ?? null;
    }

    if (apiKey) {
        Axios.defaults.headers.common['X-AUTH-TOKEN'] = apiKey;
    } else {
        console.error('API key not found');
    }

    if (widget.ReactDOMRoot || widget.rootElement) {
        exitFunction();
    }

    widget.rootElement = document.createElement('div');
    widget.rootElement.id = 'vanta-KZ-widget-root';

    document.body.appendChild( widget.rootElement);

    widget.ReactDOMRoot = ReactDOM.createRoot(
        widget.rootElement
    );

    document.body.appendChild( widget.rootElement);

    if (widget.onOpen) widget.onOpen();

    widget.ReactDOMRoot.render(
        <Provider store={store}>
            <App entryData={data} />
        </Provider>
    );
};

reportWebVitals();
