import * as yup from 'yup';
import { isEmptyValidation } from './isEmptyValidation';
import { isMinLengthValidation } from './isMinLengthValidation';
import { IValidation } from '../shared/types/IValidation';

const chain = [isEmptyValidation, isMinLengthValidation(6)];
export const emailValidator = (value: string): IValidation => {
    for (const chainElement of chain) {
        const result = chainElement(value);
        if (result.errorMessage) return result;
    }
    const schema = yup.string().email();
    const isValid = schema.isValidSync(value);
    if (!isValid)
        return {
            errorMessage: 'Email должен быть вида [text@text.text]',
        };
    return { errorMessage: '' };
};
