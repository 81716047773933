import { IValidation } from '../shared/types/IValidation';

//Владислав здравствуйте, правила генерации ИИН имеют такой вид.
// ИИН содержит 12 цифр и состоит из четырех частей:
// 1) первая часть — состоит из 6 цифр и включает в себя год рождения (две первые цифры), месяц и дату рождения физического лица;
// 2) вторая часть — состоит из 1 цифры и включает в себя пол физического лица и век его рождения и определяется следующим образом: 1 — для мужчин, родившихся в 19 веке; 2 — для женщин, родившихся в 19 веке; 3 — для мужчин, родившихся в 20 веке; 4 — для женщин, родившихся в 20 веке; 5 — для мужчин, родившихся в 21 веке; 6 — для женщин, родившихся в 21 веке. В дальнейшем при необходимости применяются резервные цифры 7, 8, 9, 0, использование которых возможно по истечении XXI века, при этом применяются нечетные цифры — для мужчин, четные — для женщин;
// 3) третья часть — состоит из 4 цифр и включает в себя порядковый номер регистрации в системе;
// 4) четвертая часть — состоит из 1 цифры, определяемой автоматически и являющейся контрольной цифрой.

//821119301118

// const centuryParts: { [key: string]: string } = {
//     '1': '18',
//     '2': '18',
//     '3': '19',
//     '4': '19',
//     '5': '20',
//     '6': '20',
// };
export const iinValidator = (value: string): IValidation => {
    if (!value) return { errorMessage: 'ИИН обязателен' };
    if (value.length !== 12) return { errorMessage: 'Длинна ИИН должа быть 12 символов' };
    const iinArray = value.split('');

    const validate = (iin: string[], offset = 0): boolean => {
        const lastDigit = Number(iin[iin.length - 1]);
        if (isNaN(lastDigit)) return false;
        const mainDigits = iin.slice(0, iin.length - 1);
        let checkSum = 0;
        for (let i = 0; i < mainDigits.length; i++) {
            const currentDigit = Number(mainDigits[i]);
            if (isNaN(currentDigit)) return false;
            checkSum += (i + 1 + offset) * currentDigit;
        }
        const remainderOfTheDivision = checkSum % 11;
        if (remainderOfTheDivision === 10 && offset === 0) {
            return validate(iin, 2);
        }
        return remainderOfTheDivision === lastDigit;
    };

    const isValid = validate(iinArray);
    if (!isValid) return { errorMessage: 'Некорректный ИИН' };
    return { errorMessage: '' };
};
