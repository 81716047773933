export enum KeyboardKeys {
	backspace = 'Backspace',
	tab = 'Tab',
	enter = 'Enter',
	shift = 'Shift',
	meta = 'Meta',
	control = 'Control',
	alt = 'Alt',
	pause = 'Pause',
	capsLock = 'CapsLock',
	escape = 'Escape',
	space = 'Space',
	pageUp = 'PageUp',
	pageDown = 'PageDown',
	end = 'End',
	home = 'Home',
	leftArrow = 'ArrowLeft',
	upArrow = 'ArrowUp',
	rightArrow = 'ArrowRight',
	downArrow = 'ArrowDown',
	insert = 'Insert',
	delete = 'Delete',
	numLock = 'NumLock',
}
