import React, { useMemo } from 'react';
import classes from './header.module.css';
import type { InitData } from '../../models';
import { moneyFormat } from '../../utils/moneyFormat';
import closeSvg from './../../assets/images/icons/cross.svg';
import DNSSvg from '../../assets/images/icons/DNS.svg';

type HeaderProps = {
    onClickCloseHandler: () => void;
} & Pick<InitData, 'orderNumber' | 'amount'>;
const Header = ({ orderNumber, amount, onClickCloseHandler }: HeaderProps) => {
    const shopIcon = useMemo(() => {
        return (
            <div className={classes.header__logo}>
                <img className={classes.header__logo_img} src={process.env.REACT_APP_PUBLIC_URL + DNSSvg} alt="logo" />
            </div>
        );
    }, []);

    return (
        <div className={classes.widget__header}>
            <div className={classes.widget__header_info}>
                {shopIcon}
                <div className={classes.header__orderData}>
                    <span title={orderNumber} className={classes.header__orderData_order}>
                        ЗАКАЗ № {orderNumber}
                    </span>
                    <span className={classes.header__orderData_amount}>
                        Сумма заказа {moneyFormat(amount)}
                    </span>
                </div>
            </div>
            <div className={classes.header__close} onClick={onClickCloseHandler}>
                <img className={classes.header__close_icon} src={process.env.REACT_APP_PUBLIC_URL + closeSvg} alt="close" />
            </div>
        </div>
    );
};
export default Header;