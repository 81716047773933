import { AxiosError } from 'axios';
import { ICalculationInitConfig } from '../models';
import { ICalculatorParams } from '../models/calculator/CaclulatorParams';
import { CalculatorBank } from '../models/CalculatorBank';
import { ResponseWithPaginator_V2 } from '../shared/types/API/IResponse_V2';
import { ResponseErrorType } from '../shared/types/API/ResponseSuccessType';
import axios, { ErrorController } from './Axios';

export abstract class CalculatorService {
    static init(): Promise<ICalculationInitConfig> {
        return axios
            .get<ICalculationInitConfig>('/calculator/bank-products/init-config')
            .then((response) => {
                return response.data;
            })
            .catch((error: AxiosError<ResponseErrorType>) => {
                return ErrorController(error);
            });
    }
    static calculate(params: ICalculatorParams): Promise<ResponseWithPaginator_V2<CalculatorBank>> {
        return axios
            .post<ResponseWithPaginator_V2<CalculatorBank>>('/calculator/bank-products/calculate', {
                ...params,
            })
            .then((response) => {
                return response.data;
            })
            .catch((error: AxiosError<ResponseErrorType>) => {
                return ErrorController(error);
            });
    }
}
