class PopupWindow {
    private _window: Window | null = null;
    set window(window: Window | null) {
        this._window = window;
    }
    get window() {
        return this._window;
    }
}

export default new PopupWindow();
