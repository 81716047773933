type ErrorStatusCodesType =
    | -1
    | 400
    | 401
    | 403
    | 404
    | 405
    | 406
    | 407
    | 408
    | 409
    | 410
    | 411
    | 412
    | 413
    | 414
    | 415
    | 416
    | 417
    | 418
    | 419
    | 420
    | 421
    | 423
    | 424
    | 425
    | 426
    | 428
    | 429
    | 431
    | 451
    | 500
    | 501
    | 502
    | 503
    | 504
    | 505
    | 506
    | 507
    | 508
    | 509
    | 510
    | 511;

export type ResponseErrorType = SimpleResponseError | ValidationResponseError;

export type SimpleResponseError = {
    type?: string;
    title: string;
    detail: string;
    status: ErrorStatusCodesType;
};

type Parameter = {
    propertyPath: string;
    title: string;
};

type Violations = {
    propertyPath: string;
    title: string;
    type: string;
    parameters: Parameter[];
};
export type ValidationResponseError = {
    violations: Violations[];
} & Omit<SimpleResponseError, 'status'>;

export const isValidationResponseError = (error: unknown): error is ValidationResponseError => {
    return !!(error as ValidationResponseError).violations;
};
