import React from 'react';
import { ValidationResponseError } from '../../shared/types/API/ResponseSuccessType';
import uuid from 'react-uuid';

const ValidationError = ({ title, violations }: ValidationResponseError) => {
    return (
        <div>
            <span className={'font-bold text-primaryRed'}>{title}</span>
            {violations.map((item, index) => {
                return (
                    <div key={uuid()} className={'mb-2 last:mb-0'}>
                        <span>{item.propertyPath} - </span>
                        <span>{item.title}</span>
                        {index !== violations.length - 1 ? <hr className={'mt-2'} /> : null}
                    </div>
                );
            })}
        </div>
    );
};
export default ValidationError;
