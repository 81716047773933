import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICartItem } from '../../models/Cart';
import { Order } from '../../models/Order';
import { AppStatuses } from '../../shared/enums/AppStatuses';

interface AppState {
    status: AppStatuses;
    order: Order;
}

const initState: AppState = {
    status: AppStatuses.FLOW,
    order: {
        orderNumber: '',
        cart: [],
        retailPointId: '',
    },
};

export const appSlice = createSlice({
    name: 'application',
    initialState: initState,
    reducers: {
        setAppStatus: (state, action: PayloadAction<AppStatuses>) => {
            state.status = action.payload;
        },
        setCart: (state, action: PayloadAction<ICartItem[]>) => {
            state.order.cart = [];
            for (const cartItem of action.payload) {
                cartItem.price = Number(cartItem.price);
                cartItem.sku = Number(cartItem.sku);
                state.order.cart.push(cartItem);
            }
        },
        setOrderNumber: (state, action: PayloadAction<string>) => {
            state.order.orderNumber = action.payload;
        },
        setRetailPointId: (state, action: PayloadAction<string>) => {
            state.order.retailPointId = action.payload;
        },
        clearAppStore: () => {
            return initState;
        },
    },
});

export const { setAppStatus, setCart, setRetailPointId, setOrderNumber, clearAppStore } =
    appSlice.actions;
export default appSlice.reducer;
