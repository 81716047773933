import React, { CSSProperties } from 'react';
import classes from './loader.module.css';

interface Props {
    color?: string;
    styles?: CSSProperties;
    classes?: string;
}
export const Loader = ({ color = 'black', styles, classes: customClasses }: Props) => {
    return (
        <div style={{ ...styles }} className={`${classes.loader} ${customClasses}`}>
            <div className={classes.loader__wrapper}>
                <div style={{ background: color }} className={`${classes.bar} ${classes.bar1}`} />
                <div style={{ background: color }} className={`${classes.bar} ${classes.bar2}`} />
                <div style={{ background: color }} className={`${classes.bar} ${classes.bar3}`} />
                <div style={{ background: color }} className={`${classes.bar} ${classes.bar4}`} />
                <div style={{ background: color }} className={`${classes.bar} ${classes.bar5}`} />
                <div style={{ background: color }} className={`${classes.bar} ${classes.bar6}`} />
                <div style={{ background: color }} className={`${classes.bar} ${classes.bar7}`} />
                <div style={{ background: color }} className={`${classes.bar} ${classes.bar8}`} />
                <div style={{ background: color }} className={`${classes.bar} ${classes.bar9}`} />
                <div style={{ background: color }} className={`${classes.bar} ${classes.bar10}`} />
                <div style={{ background: color }} className={`${classes.bar} ${classes.bar11}`} />
                <div style={{ background: color }} className={`${classes.bar} ${classes.bar12}`} />
            </div>
        </div>
    );
};
