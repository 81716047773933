import React from 'react';
import cross from '././../../assets/images/icons/failure-lg.svg';

const CriticalError = () => {
    return (
        <div className={'flex flex-1 justify-center items-center h-full flex-col mt-4'}>
            <img className={'w-[300px] h-[300px] msm:w-[150px] msm:h-[150px]'} src={cross} alt="" />
            <div className={'mt-4 text-center'}>
                <p className={'font-bold text-[30px] msm:text-[22px]'}>
                    Инициализация виджета выполнена неверно.
                </p>
                <p className={'font-bold text-[30px] msm:text-[22px]'}>Проверьте URL-адрес.</p>
            </div>
        </div>
    );
};
export default CriticalError;
