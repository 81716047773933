import React, { useState } from 'react';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { sendSmsCode } from '../../store/client/ClientSlice';
import { Loader } from '../UI/loader/Loader';
import { ResendPhoneVerificationButton } from './ResendPhoneVerificationButton';

interface IProps {
    isLoading: boolean;
    isDone: boolean;
}

export const PhoneVerificationFooter = ({ isLoading, isDone }: IProps) => {
    const id = useAppSelector((state) => state.clientStore.clientVerification.sendCode.id);
    const { phone, token, isSendSmsLoading, hasAttempts } = useAppSelector((state) => {
        const { phone } = state.clientStore.clientData;
        const {
            verify: { token },
            sendCode: { hasAttempts },
        } = state.clientStore.clientVerification;
        const { isSendSmsLoading } = state.clientStore;
        return {
            phone,
            token,
            isSendSmsLoading,
            hasAttempts,
        };
    });
    const [needResetTimer, setNeedResetTimer] = useState(false);
    const dispatch = useAppDispatch();
    const sendNewSms = () => {
        dispatch(sendSmsCode(phone))
            .unwrap()
            .finally(() => {
                setNeedResetTimer(true);
            });
    };
    return (
        <div className={'mt-8 self-center'}>
            <div className={'h-10'}>
                <div className={`${isLoading || token ? 'hidden' : 'visible w-max h-max'}`}>
                    {hasAttempts ? (
                        <ResendPhoneVerificationButton
                            id={id}
                            needResetTimer={needResetTimer}
                            onResetTimer={() => {
                                setNeedResetTimer(false);
                            }}
                            onSendCodeAgain={sendNewSms}
                            isDone={isDone}
                        />
                    ) : null}
                </div>
                {isLoading || isSendSmsLoading ? <Loader /> : null}
            </div>
        </div>
    );
};
