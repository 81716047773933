import React, { memo, useCallback, useEffect, useMemo } from 'react';
import Steps from '../UI/steps';
import { StepsData } from '../../shared/constants/Steps';
import { useAppSelector } from '../../hooks/useAppSelector';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { isDisabled } from '@testing-library/user-event/dist/utils';
import { setActiveStep, setActiveStepTitle } from '../../store/StepperSlice';

const Stepper = () => {
    const { activeStep, activeStepTitle, disabledSteps, lastCompletedStepNumber } = useAppSelector(
        (state) => {
            return state.stepperStore;
        }
    );
    const dispatch = useAppDispatch();
    const onChangeStepHandler = useCallback((step: number) => {
        dispatch(setActiveStep(step));
    }, []);
    useEffect(() => {
        dispatch(setActiveStepTitle(StepsData[activeStep - 1].title));
    }, [activeStep]);
    const steps = useMemo(() => {
        return StepsData.map((step, index) => {
            let isDisabled = false;
            if (lastCompletedStepNumber < index + 1) isDisabled = true;
            if (disabledSteps.indexOf(index + 1) !== -1) isDisabled = true;
            return (
                <Steps.Step
                    key={step.id}
                    disabled={isDisabled}
                    buttonClassName={'text-[22px] font-bold'}
                />
            );
        });
    }, [isDisabled, lastCompletedStepNumber, disabledSteps]);
    return (
        <div className={'w-full flex justify-center'}>
            <Steps
                currentStep={activeStep}
                stepsClassName={'max-w-[437px]'}
                onChange={onChangeStepHandler}
                currentStepTitle={
                    <h2 className={'text-[24px] font-bold text-center'}>{activeStepTitle}</h2>
                }>
                {steps}
            </Steps>
        </div>
    );
};
export default memo(Stepper);
