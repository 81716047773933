import { useDebounce } from 'ahooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { InputWithSlider } from '../components/inputs';
import SendCreditRequestButton from '../components/sendCreditRequestButton';
import { Loader } from '../components/UI/loader/Loader';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import { ContentLayout } from '../layouts';
import { moneyFormat } from '../shared/utils';
import { purchaseSumSelector } from '../store/app/selectors';
import { calculate, fetchCalculatorConfig } from '../store/CalculatorSlice';
import { setActiveStep } from '../store/StepperSlice';

const FirstStep = () => {
    const dispatch = useAppDispatch();
    const [isReady, setIsReady] = useState(false);
    const { isParamsLoading, paramsConfig, params, isCalculating, bankProductCalculations } =
        useAppSelector((state) => state.calculatorStore);
    const purchaseSum = useAppSelector(purchaseSumSelector);
    const [data, setData] = useState<{
        period: number;
        initial_payment: number;
    }>({ period: params.term, initial_payment: params.fee });
    const debouncePeriod = useDebounce(data.period, { wait: 180 });
    const debounceInitPayment = useDebounce(data.initial_payment, { wait: 180 });
    useEffect(() => {
        if (!paramsConfig)
            dispatch(fetchCalculatorConfig())
                .unwrap()
                .then(({ period }) => {
                    setData((prevState) => {
                        return {
                            initial_payment: prevState.initial_payment,
                            period: period.min,
                        };
                    });
                    setIsReady(true);
                });
        else setIsReady(true);
    }, []);

    useEffect(() => {
        if (isReady && purchaseSum && debouncePeriod > 0) {
            dispatch(
                calculate({
                    term: debouncePeriod,
                    fee: debounceInitPayment,
                    sum: purchaseSum,
                    services: [],
                    type: 'INSTALLMENT',
                })
            );
        }
    }, [isReady, debouncePeriod, debounceInitPayment, purchaseSum]);

    const onChangeData = useCallback((fieldId: string, value: number) => {
        setData((prevState) => {
            return {
                ...prevState,
                [fieldId]: value,
            };
        });
    }, []);
    const monthPayment = useMemo(() => {
        let minMonthPayment = 0;
        if (!isCalculating) {
            bankProductCalculations.forEach((bank) => {
                bank.bankProducts.forEach((product) => {
                    if (minMonthPayment === 0) minMonthPayment = product.periodPayment;
                    else if (minMonthPayment > product.periodPayment)
                        minMonthPayment = product.periodPayment;
                });
            });
        }
        return minMonthPayment;
    }, [isCalculating]);

    const onSendClickHandler = () => {
        dispatch(setActiveStep(2));
    };
    if (isParamsLoading && !isReady)
        return (
            <ContentLayout
                styles={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                <Loader />
            </ContentLayout>
        );
    return (
        <ContentLayout>
            <div className={'flex msm:flex-col'}>
                <InputWithSlider
                    name={'period'}
                    label={'Срок кредита, мес'}
                    id={'period'}
                    defaultValue={data.period}
                    onChange={onChangeData}
                    rangeData={{
                        step: 1,
                        min: paramsConfig?.period.min ?? 0,
                        max: paramsConfig?.period.max ?? 96,
                    }}
                    customClasses={'mr-4 msm:mr-0 msm:mb-4'}
                />
                <InputWithSlider
                    name={'initial_payment'}
                    label={'Первоначальный взнос, Т'}
                    id={'initial_payment'}
                    defaultValue={data.initial_payment}
                    formattedValue={moneyFormat(data.initial_payment, true)}
                    onChange={onChangeData}
                    rangeData={{
                        step: 2,
                        min: paramsConfig?.initialPayment.min ?? 0,
                        max: Math.floor(
                            purchaseSum * ((paramsConfig?.initialPayment.max || 90) / 100)
                        ),
                    }}
                />
            </div>
            <h3
                className={
                    'mt-[40px] h-[54px] text-center text-black text-[36px] md:text-[28px] font-bold'
                }>
                {isCalculating ? <Loader /> : null}
                {bankProductCalculations.length && monthPayment
                    ? `Ежемесячный платеж от ${moneyFormat(monthPayment)}`
                    : null}
                {!bankProductCalculations.length && !isCalculating
                    ? 'Банковский продукт не найден'
                    : null}
            </h3>
            <SendCreditRequestButton
                disabled={isCalculating || !bankProductCalculations.length || !monthPayment}
                onClick={onSendClickHandler}
            />
        </ContentLayout>
    );
};
export default FirstStep;
