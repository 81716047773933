import { useEffect, useRef } from 'react';

type UseMessageOfChangeScreenSizeProps = {
    element: HTMLDivElement | null;
};
export const useMessageOfChangeScreenSize = ({ element }: UseMessageOfChangeScreenSizeProps) => {
    const timer = useRef<ReturnType<typeof setTimeout> | null>(null);
    const onResizeHandler = () => {
        if (!element) return;
        if (timer.current) clearTimeout(timer.current);
        timer.current = setTimeout(() => {
            window.parent.postMessage(
                {
                    type: 'resize',
                    width: element.clientWidth,
                    height: element.clientHeight,
                },
                '*'
            );
        }, 180);
    };
    useEffect(() => {
        if (element) {
            window.addEventListener('resize', onResizeHandler);
            onResizeHandler();
        }
        return () => {
            window.removeEventListener('resize', onResizeHandler);
            if (timer.current) clearTimeout(timer.current);
        };
    }, [element]);
};
