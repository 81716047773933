import React from 'react';
import { ResponseErrorType } from '../../shared/types/API/ResponseSuccessType';

const SimpleError = ({ detail, title }: ResponseErrorType) => {
    return (
        <div>
            <span className={'font-bold text-primaryRed'}>{title}</span>
            <p>{detail}</p>
        </div>
    );
};
export default SimpleError;
