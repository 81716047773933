import * as yup from 'yup';
import 'yup-phone';
import { IValidation } from '../shared/types/IValidation';

export const phoneValidator = (value: string, isRequired = true): IValidation => {
    let scheme = yup.string().phone();
    if (isRequired) scheme = yup.string().phone().required();
    const isValid = scheme.isValidSync(value);
    if (!isValid) {
        return {
            errorMessage: 'Введен некорректный номер телефона',
        };
    }
    return { errorMessage: '' };
};
