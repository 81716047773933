import { IValidation } from '../shared/types/IValidation';
import * as yup from 'yup';

export const fioValidator = (value: string): IValidation => {
    const fioScheme = yup
        .string()
        .required('ФИО обязательно для заполнения')
        .test(
            '',
            'ФИО не должно состоять из букв латинского алфавита',
            (value: string | undefined) => {
                if (!value) return false;
                const clearValue = value.replace(/[^A-z]/g, '');
                return !clearValue.length;
            }
        )
        .test(
            '',
            'ФИО не должно включать в себя цифры и спец. символы',
            (value: string | undefined) => {
                if (!value) return false;
                const clearValue = value.replace(/[А-яЁёӘәҒғҚқҢңӨөҰұҮүҺһІі -]/g, '');
                return !clearValue.length;
            }
        )
        .min(8, 'ФИО должно быть не менее 8 символов')
        .max(100, 'ФИО должно быть не более 100 символов');

    try {
        fioScheme.validateSync(value);
        return {
            errorMessage: '',
        };
    } catch (error) {
        return {
            errorMessage: (error as { message: string }).message,
        };
    }
};
