import React from 'react';
import Button from '../UI/button/Button';
import classes from './sendCreditRequestButton.module.css';

interface IProps {
    onClick: () => void;
    disabled: boolean;
}
const SendCreditRequestButton = ({ onClick, disabled }: IProps) => {
    return (
        <Button disabled={disabled} className={classes.button} onClickHandler={onClick}>
            <span className={classes.button_text}>Далее</span>
        </Button>
    );
};
export default SendCreditRequestButton;
