import React, { useEffect, useRef } from 'react';
import Banks from '../components/Banks/Banks';
import { Loader } from '../components/UI/loader/Loader';
import { useAppDispatch } from '../hooks/useAppDispatch';
import { useAppSelector } from '../hooks/useAppSelector';
import { ContentLayout } from '../layouts';
import { ScoringStatuses } from '../models';
import { LoanService } from '../services/loan.service';
import { setActiveStep, setDisabledSteps } from '../store/StepperSlice';
import { scoringResult, setIsDecisionsLoading } from '../store/scoring/scoring';
import { catchApiError } from '../utils/catchError';
import store from '../store';

const ThirdStep: React.FC = () => {
    const { loanBlankId } = useAppSelector((state) => state.loanStore);
    const { decisions, isLoading } = useAppSelector((state) => state.scoringStore);
    const dispatch = useAppDispatch();
    const timeout = useRef<ReturnType<typeof setTimeout> | null>(null);

    const getScoringResult = () => {
        let needStop = true;
        if (timeout.current) {
            clearTimeout(timeout.current);
            timeout.current = null;
        }
        dispatch(scoringResult({ loanBlankId: loanBlankId }))
            .unwrap()
            .then(({ items }) => {
                for (const datum of items) {
                    if (datum.status === ScoringStatuses.SCORING) {
                        needStop = false;
                        break;
                    }
                }
            })
            .finally(() => {
                if (!needStop) {
                    timeout.current = setTimeout(() => {
                        getScoringResult();
                    }, 3000);
                } else {
                    if (timeout.current) {
                        clearTimeout(timeout.current);
                        timeout.current = null;
                    }
                }
            });
    };

    useEffect(() => {
        if (!loanBlankId) {
            alert('Отсутсвует id заявки');
            dispatch(setActiveStep(1));
        } else {
            dispatch(setIsDecisionsLoading(true));
            dispatch(setDisabledSteps([1, 2]));
            LoanService.startScoring(loanBlankId)
                .then(() => {
                    getScoringResult();
                })
                .catch((err) => {
                    catchApiError(err, dispatch);
                });
        }
        return () => {
            if (timeout.current) {
                clearTimeout(timeout.current);
            }
        };
    }, []);
    return (
        <ContentLayout>
            <div className={'flex flex-1 justify-center'}>
                {isLoading || !decisions.length ? <Loader /> : null}
            </div>
            {decisions.length > 0 && <Banks banks={decisions} />}
        </ContentLayout>
    );
};
export default ThirdStep;
