import React from 'react';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import { AppStatuses } from '../../shared/enums/AppStatuses';
import { clearAppStore, setAppStatus } from '../../store/app/AppSlice';
import { clearCalculatorStore } from '../../store/CalculatorSlice';
import { clearClientStore } from '../../store/client/ClientSlice';
import { clearScoringStore } from '../../store/scoring/scoring';
import { clearStepStore } from '../../store/StepperSlice';
import { clearToastStore } from '../../store/toast/ToastSlice';
import { clearLoanStore } from '../../store/loan/LoanSlice';
import Button from '../UI/button/Button';
import Image from '../UI/image';
import FailureLgSvg from './../../assets/images/icons/failure-lg.svg';
import classes from './exit.module.css';
import store from '../../store';



export const exitFunction = () => {
    const widget = window.vantaKZWidget;
    store.dispatch(clearScoringStore());
    store.dispatch(clearAppStore());
    store.dispatch(clearStepStore());
    store.dispatch(clearScoringStore());
    store.dispatch(clearClientStore());
    store.dispatch(clearLoanStore());
    store.dispatch(clearCalculatorStore());
    store.dispatch(clearToastStore());
    if (widget.ReactDOMRoot) widget.ReactDOMRoot.unmount();
    if (widget.rootElement) widget.rootElement.remove();
    if (widget.onClose) widget.onClose();
};

const Exit: React.FC = () => {
    const dispatch = useAppDispatch();
    const onBackClickHandler = () => {
        dispatch(setAppStatus(AppStatuses.FLOW));
    };
    return (
        <div className={classes.exit}>
            <div className={classes.exit__logo}>
                <Image className={classes.exit__logo_image} src={process.env.REACT_APP_PUBLIC_URL + FailureLgSvg} alt={'failureSvg'} />
            </div>
            <span className={classes.exit__title}>Завершить оформление</span>
            <div className={classes.exit__controls}>
                <Button
                    className={classes.exit__controls__exit}
                    danger={true}
                    onClickHandler={exitFunction}>
                    <span className={classes.exit__controls__exit_text}>Завершить</span>
                </Button>
                <Button
                    className={classes.exit__controls_goBack}
                    onClickHandler={onBackClickHandler}>
                    <span className={classes.exit__controls_goBack_text}>Вернуться</span>
                </Button>
            </div>
        </div>
    );
};

export default Exit;