import React from 'react';
import PoscreditSvg from './../../assets/images/icons/POSCREDIT.svg';
import classes from './footer.module.css';
import Image from '../UI/image';

const Footer = () => {
	return (
		<div className={classes.widget__footer}>
			<div className={classes.footer__logo}>
				<Image
					className={classes.footer__logo_icon}
					src={process.env.REACT_APP_PUBLIC_URL + PoscreditSvg}
					alt={'poscredit'}
				/>
			</div>
		</div>
	);
};
export default Footer;
