import React, { memo } from 'react';
import { BaseInputProps } from './types';
import InputMask from 'react-input-mask';
import classes from './input.module.css';
import classNames from 'classnames';

const BaseInput = ({
    onBlur,
    disabled,
    id,
    name,
    onChange,
    onFocus,
    onKeyDown,
    value,
    formattedValue,
    autoComplete,
    type,
    placeHolder,
    inputClassNames,
    isActive,
    mask,
}: BaseInputProps) => {
    const inputClasses = classNames(classes.input__field, inputClassNames);
    if (!mask)
        return (
            <input
                className={inputClasses}
                type={type}
                placeholder={placeHolder}
                id={id}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                disabled={disabled}
                name={name}
                autoComplete={autoComplete}
                value={isActive ? value : formattedValue || value}
                onChange={onChange}
            />
        );
    else {
        return (
            <InputMask
                mask={mask}
                className={inputClasses}
                type={type}
                placeholder={placeHolder}
                id={id}
                onBlur={onBlur}
                onKeyDown={onKeyDown}
                onFocus={onFocus}
                disabled={disabled}
                name={name}
                autoComplete={autoComplete}
                value={isActive ? value : formattedValue || value}
                onChange={onChange}
            />
        );
    }
};
export default memo(BaseInput);
