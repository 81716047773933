import React, { useCallback } from 'react';
import { RadioSlider } from '../UI/radioSlider/RadioSlider';
import { IRadioSliderItem } from '../UI/radioSlider/types';

interface Props {
    slides: IRadioSliderItem<string>[];
    onChangeSlide: (selectedSlide: IRadioSliderItem<string>) => void;
    defaultSlide: IRadioSliderItem<string>;
    customClasses?: string;
}
const PcMobileSlider = ({ onChangeSlide, defaultSlide, slides, customClasses }: Props) => {
    const onSelectSlideHandler = useCallback((slide: IRadioSliderItem<string>) => {
        onChangeSlide(slide);
    }, []);
    return (
        <RadioSlider
            classes={customClasses}
            slides={slides}
            selectedSlide={defaultSlide}
            onSelect={onSelectSlideHandler}
        />
    );
};
export default PcMobileSlider;
