import axios, { AxiosError } from 'axios';
import { ResponseErrorType } from '../shared/types/API/ResponseSuccessType';


const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

instance.interceptors.request.use(
    (config) => {
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export const defaultResponseError: ResponseErrorType = {
    title: '',
    type: '',
    detail: 'Произошла непредвиденная ошибка',
    status: -1,
};

export const ErrorController = (error: AxiosError<ResponseErrorType> | null) => {
    if (error?.message === 'canceled') {
        return Promise.reject(null);
    }
    if (error && error?.response?.status === 403) {
        return Promise.reject({ title: 'Ошибка', detail: 'Доступ запрещен' });
    }
    if (error && error.response?.status === 500) {
        return Promise.reject({ title: 'Ошибка', detail: 'Внутренняя ошибка' });
    }
    if (!error || !error.response?.data.detail) return Promise.reject(defaultResponseError);
    return Promise.reject(error.response.data);
};

export default instance;
