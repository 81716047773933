import React, { Fragment } from 'react';
import { createEmptyArray } from '../../../shared/utils/createEmptyArray';
import classes from './skeleton.module.css';

interface IProps {
    number?: number;
    isNotEven?: boolean;
}

export const SkeletonRow: React.FC<IProps> = ({ number = 6, isNotEven }: IProps) => {
    const items = createEmptyArray(number).map((_, index) => {
        return (
            <Fragment key={index}>
                <div className={'flex flex-col flex-1'}>
                    <div className={classes.skeleton__bank__row_value} />
                </div>
                {index !== number - 1 && <div className={classes.skeleton__bank__row_line} />}
            </Fragment>
        );
    });
    return (
        <div
            className={`flex w-16 px-0 py-0 justify-between lg:flex-col ${
                isNotEven ? 'bg-thirdGrey' : ''
            }`}>
            {items}
        </div>
    );
};

export const SkeletonRows = ({ number }: { number: number }) => {
    const rowItems = createEmptyArray(number).map((_, index) => {
        const isNotEven = index % 2 !== 0;
        return <SkeletonRow isNotEven={isNotEven} key={index} />;
    });
    return <div className={'flex flex-col'}>{rowItems}</div>;
};
