import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface StepperState {
    activeStep: number;
    activeStepTitle: string;
    disabledSteps: number[];
    lastCompletedStepNumber: number;
}

const initState: StepperState = {
    activeStep: 1,
    lastCompletedStepNumber: 1,
    activeStepTitle: '',
    disabledSteps: [],
};

export const stepperSlice = createSlice({
    name: 'stepper',
    initialState: initState,
    reducers: {
        setActiveStep: (state, action: PayloadAction<number>) => {
            const currentStep = action.payload;
            state.activeStep = currentStep;
            if (currentStep > state.lastCompletedStepNumber)
                state.lastCompletedStepNumber = currentStep;
        },

        setActiveStepTitle: (state, action: PayloadAction<string>) => {
            state.activeStepTitle = action.payload;
        },
        setDisabledSteps: (state, action: PayloadAction<number[]>) => {
            state.disabledSteps = action.payload;
        },
        setLastCompletedStepNumber: (state, action: PayloadAction<number>) => {
            state.lastCompletedStepNumber = action.payload;
        },
        clearStepStore: () => {
            return initState;
        },
    },
});

export const {
    setActiveStep,
    setActiveStepTitle,
    setDisabledSteps,
    setLastCompletedStepNumber,
    clearStepStore,
} = stepperSlice.actions;
export default stepperSlice.reducer;
