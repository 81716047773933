import classNames from 'classnames';
import React, { FC, useRef } from 'react';
import { useMessageOfChangeScreenSize } from '../../hooks/useMessageOfChangeScreenSize';
import type { ILayout } from '../types';
import classes from './widget.layout.module.css';

type WidgetLayoutProps = {
    Header: React.ReactElement;
    Footer: React.ReactElement;
} & ILayout;
const WidgetLayout: FC<WidgetLayoutProps> = ({
    children,
    styles,
    Header,
    Footer,
    customClasses,
}) => {
    const widgetLayoutRef = useRef<HTMLDivElement | null>(null);
    useMessageOfChangeScreenSize({ element: widgetLayoutRef?.current || null });
    return (
        <div
            ref={widgetLayoutRef}
            className={classNames(classes.widgetLayout, customClasses)}
            style={{ ...styles }}>
            <div className={classes.widgetLayout__wrapper}>
                {Header}
                <div className={classes.widgetLayout__content}>{children}</div>
                {Footer}
            </div>
        </div>
    );
};
export default WidgetLayout;
